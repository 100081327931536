<template>
  <div class="star-designer">
    <swiper class="banner" :options="swiperOption">
      <swiper-slide class="swiper-slide" v-for="(item, index) in bannerList" :key="index">
        <div class="swiper-content">
          <img :src="item.pic.picUrl" alt />
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
      <!-- 左右箭头 -->
      <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
      <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
    </swiper>
    <div class="title">品牌设计师</div>
    <div class="container">
      <div class="star-item" v-for="(item, index) in starList" :key="index" @click="tzlast(item.id)">
        <div class="img">
          <img :src="item.head.picUrl" alt />
        </div>
        <div class="desc">{{item.des}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_STAR_DEGIGNER } from "../../service/other";

export default {
  name: "StarDesigner",
  data() {
    return {
      bannerList: [],
      swiperOption: {
        //显示分页
        pagination: {
          el: ".swiper-pagination"
        },
        //设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        //自动轮播
        autoplay: {
          delay: 3000,
          //当用户滑动图片后继续自动轮播
          disableOnInteraction: false
        },
        //开启循环模式
        loop: true
        // slidesPerView: "auto"
        // spaceBetween: 10
      },
      starList: []
    };
  },
  computed: {},
  created() {
    this.getStartList();
  },
  methods: {
    tzlast(id){
      this.$router.push({
        path:'/datalists',
        query:{
          ids:id
        }
      })
    },
    getStartList() {
      GET_STAR_DEGIGNER().then(res => {
        // console.log(res);
        if (res.status === 200) {
          this.starList = res.attachment.datas;
          this.bannerList = res.attachment.ads;
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.star-designer {
  .banner {
    width: 1596px;
    height: 600px;
    margin: 0 auto;
  }
  .swiper-slide {
    width: 793px;
  }
  .swiper-content {
    width: 1596px;
    height: 600px;
    overflow: hidden !important; 
    img {
      width: 100%;
      height: 100%;
      object-fit: cover !important;
    }
  }
  .title {
    margin: 80px 0 30px;
    font-size: 26px;
    line-height: 1;
    text-align: center;
  }
  .container {
    margin: 0 auto 196px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1596px;
    padding: 30px 66px 0;
    background: #f5f5f5;
    .star-item {
      width: 460px;
      padding-bottom: 45px;
      &:hover{
        cursor: pointer;
      }
      .img {
        width: 100%;
        height: 360px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .desc {
        font-size: 18px;
        line-height: 30px;
        color: #666;
        margin-top: 10px;
      }
    }
  }
}
</style>
